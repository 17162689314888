<template>
  <v-app v-if="pathInfo">
    <v-app-bar
        app
        color="white"
        height="60"
        dense
        class="app-bar"
    >
      <v-row class="width-per-100 height-per-100 justify-center">
        <div class="width-per-100 height-per-100 d-flex justify-space-between menu">
          <div class="width-per-100" style="min-width: 350px;">
            <v-hover
                v-slot="{ hover }"
                v-for="(link,index) in links.leftList"
                :key="index">
              <div class="height-per-100 d-inline">
                <v-btn
                    height="100%"
                    light depressed plain
                    @mouseenter="showMenuChildren = true;"
                    :color="currentMenu === link.router ? '#0568FD':''"
                    :ripple="false"
                    class="px-0"
                    :class="index !== 0 ? 'ml-16':''"
                    @click.stop="handleMenuClick(link, false, link);"
                >
                  <div class="menu-title">{{ link.title }}
                    <div :class="currentMenu === link.router ? 'menu-btn-active':''"></div>
                  </div>
                  <div v-if="link.children.length > 0" style="height: 30px;padding-top: 4px;">
                    <v-icon light left dense class="ml-1 mr-0 font-size-12" v-show="hover">fas fa-chevron-up</v-icon>
                    <v-icon light left dense class="ml-1 mr-0 font-size-12" v-show="!hover">fas fa-chevron-down</v-icon>
                  </div>
                </v-btn>
                <div v-if="link.children.length > 0" class="d-inline">
                  <v-fade-transition origin="center center">
                    <div class="menu-children width-per-100" v-show="hover && showMenuChildren">
                      <div class="menu-divider"><v-divider></v-divider></div>
                      <div class="menu-children-content">
                        <div v-for="(linkChildren,indexA) in link.children" :key="indexA" class="height-per-100"
                             :class="indexA !== 0 ? 'flex-grow-1':'flex-grow-1-dot-5'">
                          <v-divider vertical v-if="indexA !== 0"></v-divider>
                          <div :class="link.children.length > 1 ? indexA !== 0 ? 'ml-8':'mr-5':''">
                            <div v-if="linkChildren.title" class="font-weight-bold children-title">
                              <span>{{linkChildren.title}}</span>
                            </div>
                            <v-row class="mt-4 ml-n5">
                              <v-col class="pa-0 mb-5"
                                     style="min-height: 51px;max-height: 51px;"
                                     :cols="link.children.length > 1 ? indexA !== 0 ? 6 : 4 : 3" v-for="(content,index) in linkChildren.children"
                                     :key="index">
                                <v-hover v-slot="{ hover }">
                                  <v-row class="mr-0 cursor-pointer d-flex align-center ml-3"
                                         style="height: 60px;"
                                         @click.stop="handleMenuClick(link,true, content);">
                                    <div class="d-inline width-40">
                                      <v-avatar size="40" rounded-2>
                                        <v-img :src="content.icon"></v-img>
                                      </v-avatar>
                                    </div>
                                    <div class="d-inline children-card mt-1">
                                      <v-card color="transparent" flat>
                                        <v-card-title class="pa-0 pt-2" :class="hover ? 'bg-hover':''">
                                          {{content.title}}
                                          <v-img v-if="content.isHot"
                                                 contain max-width="24" height="10"
                                                 class="ml-1"
                                                 src="../../public/img/icons/menu/hot.png"></v-img>
                                        </v-card-title>
                                        <v-card-text class="pa-0 pb-2 mt-1" :class="hover ? 'bg-hover':''" style="line-height: 1.4">
                                          <span style="opacity: 0.6">{{content.explain}}</span>
                                        </v-card-text>
                                      </v-card>
                                    </div>
                                  </v-row>
                                </v-hover>
                              </v-col>
                            </v-row>
                          </div>
                        </div>
                      </div>
                    </div>
                  </v-fade-transition>
                </div>
              </div>
            </v-hover>
          </div>
          <div class="width-per-100 d-inline-flex justify-center">
            <v-btn
                plain elevation="0"
                height="100%"
                min-width="100"
                max-width="200"
                to="/"
                class="pl-0 logo-btn"
            >
              <v-img
                  alt="logo"
                  contain
                  height="36"
                  src="../assets/login/logo_lan.png"
              />
            </v-btn>
          </div>
          <div class="width-per-100 d-inline-flex justify-end">
            <v-hover
                v-slot="{ hover }"
                v-for="(link,index) in links.rightList"
                :key="index">
              <div class="height-per-100 d-inline">
                <v-btn
                    height="100%"
                    light depressed plain
                    :color="currentMenu === link.router ? '#0568FD':''"
                    :ripple="false"
                    class="px-0"
                    :class="index !== 0 ? 'ml-16':''"
                    @click.stop="handleMenuClick(link, false, link)"
                >
                  <div class="menu-title">{{ link.title }}
                    <div :class="currentMenu === link.router ? 'menu-btn-active':''"></div>
                  </div>
                  <span v-if="link.children.length > 0">
                    <v-icon light left dense class="ml-1 font-size-12" v-show="hover">fas fa-chevron-up</v-icon>
                    <v-icon light left dense class="ml-1 font-size-12" v-show="!hover">fas fa-chevron-down</v-icon>
                  </span>
                </v-btn>
              </div>
            </v-hover>
          </div>
        </div>
      </v-row>
    </v-app-bar>
    <v-main style="position: relative;z-index: 6;">
      <router-view/>
    </v-main>
    <v-footer
        height="360"
        color="#151C29"
        class="justify-center align-start"
    >
      <v-container class="pa-0 footer-container">
        <v-row class="ma-0 mt-5" :class="isIE == true ? 'isIE' : ''">
          <v-col cols="3" class="d-flex justify-start pa-0">
            <v-card color="transparent" flat class="pt-2">
              <v-img
                  class="ml-n1"
                  width="120"
                  alt="logo"
                  contain
                  src="../assets/login/logo_bai.png"
              />
              <v-card-text class="pa-0 mt-14">
                <v-icon dense style="color: #AEB8C8">fas fa-phone-alt</v-icon>
                <span class="ml-2 font-size-16" style="color: #AEB8C8">010-63037996</span>
              </v-card-text>
              <v-card-text class="pa-0 mt-5">
                <v-icon dense style="color: #AEB8C8">fas fa-map-marker-alt</v-icon>
                <span class="ml-3 font-size-14" style="color: #AEB8C8">北京朝阳区广渠路3号竞园艺术中心商8</span>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="2" class="d-flex justify-center pa-0" style="height: 180px;">
            <v-divider vertical dark></v-divider>
          </v-col>
          <v-col cols="3" class="pa-0">
            <v-card color="transparent" flat class="pt-2">
              <v-card-text class="pa-0 font-size-16 font-weight-bold color-FFF">产品与服务</v-card-text>
              <div class="footer-divider"></div>
              <div class="d-flex mt-5">
                <v-card color="transparent" flat
                        :class="index > 0 ? 'ml-15':''"
                        v-for="(footerLink, index) in serviceProducts" :key="index">
                  <v-hover v-slot="{ hover }" v-for="(linkInfo, linkIndex) in footerLink" :key="linkIndex">
                    <v-card-text class="pa-0 content-info cursor-pointer"
                                 :class="[linkIndex > 0 ? 'py-2':'pb-1',hover ? 'bg-hover':'']"
                                 @click.stop="handleFooterClick(linkInfo.router,linkInfo.isNewWindow,linkInfo.outerChain)">{{ linkInfo.name }}</v-card-text>
                  </v-hover>
                </v-card>
              </div>
            </v-card>
          </v-col>
          <v-col cols="3" class="pa-0">
            <v-card color="transparent" flat class="pt-2 ml-8">
              <v-card-text class="pa-0 font-size-16 font-weight-bold color-FFF">解决方案</v-card-text>
              <div class="footer-divider"></div>
              <div class="d-flex mt-5">
                <v-card color="transparent" flat
                        :class="index > 0 ? 'ml-12':''"
                        v-for="(footerLink, index) in solutions" :key="index">
                  <v-hover v-slot="{ hover }" v-for="(linkInfo, linkIndex) in footerLink" :key="linkIndex">
                    <v-card-text class="pa-0 content-info cursor-pointer"
                                 :class="[linkIndex > 0 ? 'py-2':'pb-1',hover ? 'bg-hover':'']"
                                 @click.stop="handleFooterClick(linkInfo.router,linkInfo.isNewWindow,linkInfo.outerChain)">{{ linkInfo.name }}</v-card-text>
                  </v-hover>
                </v-card>
              </div>
            </v-card>
          </v-col>
          <v-col cols="1" class="pa-0">
            <v-card color="transparent" flat class="mt-5">
              <v-card-text class="d-flex justify-center pa-0 qr-code">
                <v-img
                    alt="二维码"
                    contain
                    src="../../public/img/icons/menu/qrCode.png"
                    width="108"
                    max-width="108"
                    height="108"
                    max-height="108"
                />
              </v-card-text>
              <v-card-text class="d-flex justify-center pa-0 mt-3 content-info font-size-16">官方公众号</v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <div class="width-per-100 d-flex justify-center align-center" style="position: absolute;bottom: 0;left: 0;height: 60px;background-color: #090E19">
          <div class="max-width-1200" style="color: #969799;font-size: 12px;">
            © COPYRIGHT 2021 . ALL RIGHTS RESERVED. 北京掌中飞天科技股份有限公司版权所有
            <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010502037637" target="_blank">京公网安备11010502037637号 </a>
            <a href="https://beian.miit.gov.cn" target="_blank"> 京ICP备09083236号</a>
          </div>
        </div>
      </v-container>
    </v-footer>
    <div class="suspend-btn">
      <div class="hover-group">
        <div class="group-btn">
          <v-row class="pa-0" @click.stop="currentHover = 'phone'" @mouseenter="currentHover = 'phone'" @mouseleave="currentHover = ''">
            <v-img width="50" height="50" contain src="../../public/img/icons/menu/phone.png"></v-img>
          </v-row>
          <div class="d-flex justify-center">
            <div style="width: 30%"><v-divider></v-divider></div>
          </div>
          <v-row class="pa-0" @click.stop="currentHover = 'tryout'">
            <v-img width="50" height="50" contain src="../../public/img/icons/menu/tryout.png"></v-img>
          </v-row>
          <div class="d-flex justify-center">
            <div style="width: 30%"><v-divider></v-divider></div>
          </div>
          <v-row class="pa-0" @click.stop="currentHover = 'follow'" @mouseenter="currentHover = 'follow'" @mouseleave="currentHover = ''">
            <v-img width="50" height="50" contain src="../../public/img/icons/menu/follow.png"></v-img>
          </v-row>
        </div>
        <div class="hover-content" @click.stop>
          <transition name="fade" mode="out-in">
            <div class="content-phone"
                 @mouseenter="currentHover = 'phone'"
                 @mouseleave="currentHover = ''"
                 v-show="currentHover === 'phone'">
              <v-card color="transparent" flat>
                <v-row class="pa-0">
                  <div class="d-inline width-40">
                    <v-avatar size="40" rounded-2>
                      <v-img contain src="../../public/img/icons/menu/cooperation.png"></v-img>
                    </v-avatar>
                  </div>
                  <div class="d-inline ml-3 mt-n3">
                    <v-card color="transparent" flat>
                      <v-card-title class="pa-0 font-size-20 mt-2">
                        商户合作
                      </v-card-title>
                      <v-card-text class="pa-0 mt-3 font-size-16" style="line-height: 1.4">yizhe.han@ophyer.com</v-card-text>
                    </v-card>
                  </div>
                </v-row>
                <v-row class="pa-0 mt-8">
                  <div class="d-inline width-40">
                    <v-avatar size="40" rounded-2>
                      <v-img width="40" height="40" contain src="../../public/img/icons/menu/consult.png"></v-img>
                    </v-avatar>
                  </div>
                  <div class="d-inline ml-3 mt-n3">
                    <v-card color="transparent" flat>
                      <v-card-title class="pa-0 font-size-20 mt-2">
                        SaaS 产品咨询
                      </v-card-title>
                      <v-card-text class="pa-0 mt-3 font-size-16" style="line-height: 1.4">010-63037996</v-card-text>
                    </v-card>
                  </div>
                </v-row>
                <v-row class="pa-0 mt-8">
                  <div class="d-inline width-40">
                    <v-avatar size="40" rounded-2>
                      <v-img width="40" height="40" contain src="../../public/img/icons/menu/joinIn.png"></v-img>
                    </v-avatar>
                  </div>
                  <div class="d-inline ml-3 mt-n3">
                    <v-card color="transparent" flat>
                      <v-card-title class="pa-0 font-size-20 mt-2">
                        招商加盟
                      </v-card-title>
                      <v-card-text class="pa-0 mt-3 font-size-16" style="line-height: 1.4">13916184657</v-card-text>
                    </v-card>
                  </div>
                </v-row>
              </v-card>
            </div>
          </transition>
          <transition name="fade" mode="out-in">
            <div class="content-tryout" v-show="currentHover === 'tryout'">
              <v-card color="transparent" flat>
                <div class="close-btn" @click.stop="currentHover = '';">
                  <v-img class="icon" src="../../public/img/icons/menu/close.png"
                         max-width="12" height="12" contain></v-img>
                </div>
                <v-card-title class="justify-center font-size-28 pa-0 mt-7">完善信息，专属顾问马上联系您</v-card-title>
                <v-card-text class="text-align-center font-size-18 pa-0 mt-5">苏经理：18511864107</v-card-text>
                <v-form v-model="valid" class="pa-0 mt-12">
                  <v-container class="pa-0">
                    <v-row class="pa-0">
                      <v-col cols="12" class="pa-0 d-flex justify-center">
                        <div>
                          <div class="font-size-18 font-weight-bold">
                            姓名
                            <span class="ml-2" style="color: red;vertical-align: sub;">*</span>
                          </div>
                          <input placeholder="请输入您的姓名" v-model="form.name"/>
                        </div>
                      </v-col>
                      <v-col cols="12" class="pa-0 d-flex justify-center mt-5">
                        <div>
                          <div class="font-size-18 font-weight-bold">
                            电话
                            <span class="ml-2" style="color: red;vertical-align: sub;">*</span>
                          </div>
                          <input placeholder="请输入您的电话" v-model="form.phone"/>
                        </div>
                      </v-col>
                      <v-col cols="12" class="pa-0 d-flex justify-center mt-5">
                        <div>
                          <div class="font-size-18 font-weight-bold">
                            公司名称
                            <span class="ml-2" style="color: red;vertical-align: sub;">*</span>
                          </div>
                          <input placeholder="请输入您的公司名称" v-model="form.conpanyName"/>
                        </div>
                      </v-col>
                      <v-col cols="12" class="pa-0 d-flex justify-center mt-5">
                        <div>
                          <div class="font-size-18 font-weight-bold">地区</div>
                          <input placeholder="请输入您所在的地区" v-model="form.address"/>
                        </div>
                      </v-col>
                      <v-col cols="12" class="pa-0 d-flex justify-center mt-9">
                        <div class="tryout-btn" @click.stop="handleSaveTryout">提交</div>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
              </v-card>
            </div>
          </transition>
          <transition name="fade" mode="out-in">
            <div class="content-follow"
                 @mouseenter="currentHover = 'follow'"
                 @mouseleave="currentHover = ''"
                 v-show="currentHover === 'follow'">
              <v-card color="transparent" flat>
                <v-row class="pa-0 justify-center">
                  <v-img contain src="../../public/img/icons/menu/qrCode.png" width="180" height="180"></v-img>
                  <v-card-title class="font-size-16 pa-0">关注掌中飞天公众号</v-card-title>
                </v-row>
              </v-card>
            </div>
          </transition>
        </div>
      </div>
      <transition name="fade" mode="out-in">
        <div class="hover-btn"
             v-show="officialWebsite.showReturnTip"
             @click.stop="$vuetify.goTo(0, {})">
          <v-img src="../../public/img/icons/menu/returnTop.png"></v-img>
        </div>
      </transition>
    </div>
  </v-app>
  <router-view v-else/>
</template>

<script>
let domains = process.env.VUE_APP_NODE_ENV === "production" ? process.env.VUE_APP_PRODUCTION_DOMAIN : process.env.VUE_APP_TEST_DOMAIN;
domains = domains.split(",");
import { mapState } from "vuex";
import { browserVersion } from "@/utils/utils"
// let domains = process.env.NODE_ENV === "production" ? process.env.VUE_APP_PRODUCTION_DOMAIN : process.env.VUE_APP_TEST_DOMAIN;
// domains = domains.split(",");
export default {
  name: 'App',
  data: () => ({
    showHeaderMenu: ["","serviceProducts","solutions","CooperationCase","About"],
    pathInfo: true,
    currentMenu: "/",
    currentHover: "",
    valid: false,
    links: {
      leftList: [
        {
          title: "首页",
          router: "/",
          children: []
        },
        {
          title: "产品与服务",
          router: "/serviceProducts",
          children: [{
            title: "产品",
            children: [{
              title: "万目云",
              icon: require("../../public/img/icons/menu/wanmuyun.png"),
              router: "",
              explain: "赋能商业的智慧营销平台",
              isNewWindow: true,
              outerChain: domains[1]
            },{
              title: "万景云",
              icon: require("../../public/img/icons/menu/wanjingyun.png"),
              router: "/serviceProducts/SceneCloud",
              explain: "定义科技文旅新方向"
            },{
              title: "万效云",
              icon: require("../../public/img/icons/menu/wanxiaoyun.png"),
              router: "/serviceProducts/EfficiencyCloud",
              explain: "助力企业流量高效变现"
            },{
              title: "万学云",
              icon: require("../../public/img/icons/menu/wanxueyun.png"),
              router: "/serviceProducts/LearnCloud",
              explain: "一站式教学、运营、管理系统"
            },{
              title: "万界云",
              icon: require("../../public/img/icons/menu/wanjieyun.png"),
              router: "/serviceProducts/SaasCloud",
              explain: "全方位一体化商业定制服务"
            },{
              title: "万擎云",
              icon: require("../../public/img/icons/menu/wanqingyun.png"),
              router: "/serviceProducts/WanqingCloud",
              explain: "自主研发3D引擎，兼容性更强"
            }]
          },{
            title: "服务",
            children: [{
              title: "IP授权",
              icon: require("../../public/img/icons/menu/IP.png"),
              router: "/serviceProducts/IP",
              explain: "海量IP，赋能商业品牌"
            },{
              title: "视听云服务",
              icon: require("../../public/img/icons/menu/shipingyun.png"),
              router: "/serviceProducts/AudioVisualCloud",
              explain: "助力企业轻松开展视听业务"
            },{
              title: "短信服务",
              icon: require("../../public/img/icons/menu/duanxin.png"),
              router: "/serviceProducts/ShortMessage",
              explain: "即时触达全球各地"
            },{
              title: "全景拍摄服务",
              icon: require("../../public/img/icons/menu/quanjingpaishe.png"),
              router: "/serviceProducts/ShootingService",
              explain: "设备培训+制作工具使用培训"
            },{
              title: "VR带看",
              icon: require("../../public/img/icons/menu/VRdaikan.png"),
              router: "/serviceProducts/VRSceneShow",
              explain: "语音互动，让导览更高效"
            },{
              title: "数字博物馆",
              icon: require("../../public/img/icons/menu/shuzibowuguan.png"),
              router: "/serviceProducts/DigitalMuseum",
              explain: "文旅景区数字化管理服务"
            }]
          }]
        },
        {
          title: "解决方案",
          router: "/solutions",
          children: [{
            title: "行业解决方案",
            children: [{
              title: "电商零售行业",
              icon: require("../../public/img/icons/menu/OnlineRetailers.png"),
              router: "/solutions/OnlineRetailers",
              explain: "线上、线下营销导流，全渠道用户运营",
              isHot: true
            },{
              title: "房产家居行业",
              icon: require("../../public/img/icons/menu/House.png"),
              router: "/solutions/House",
              explain: "VR带看+全景直播，专注房产营销",
              isHot: true
            },{
              title: "智慧文旅",
              icon: require("../../public/img/icons/menu/IntelligentCulturalTourism.png"),
              router: "/solutions/IntelligentCulturalTourism",
              explain: "助推文旅全方位数字化产业升级",
              isHot: true
            },{
              title: "智慧教育",
              icon: require("../../public/img/icons/menu/WisdomEducation.png"),
              router: "/solutions/WisdomEducation",
              explain: "专注全景沉浸式互动教学方案"
            },{
              title: "汽车行业",
              icon: require("../../public/img/icons/menu/AutomobileIndustry.png"),
              router: "/solutions/AutomobileIndustry",
              explain: "3D模型+360环物展示，沉浸式购车体验",
              isHot: true
            },{
              title: "餐饮行业",
              icon: require("../../public/img/icons/menu/CateringTrade.png"),
              router: "/solutions/CateringTrade",
              explain: "优惠券、门店券，提升营销效果"
            }]
          }]
        },
      ],
      rightList: [
        {
          title: "合作案例",
          router: "/CooperationCase",
          children: []
        },
        {
          title: "关于我们",
          router: "/About",
          children: []
        }
      ]
    },
    fab: true,
    form: {
      name: "",
      phone: "",
      conpanyName: "",
      address: ""
    },
    showMenuChildren: false,
    serviceProducts: [
      [
        {
          name: "万目云",
          router: "",
          isNewWindow: true,
          outerChain: domains[1]
        },
        {
          name: "万景云",
          router: "/serviceProducts/SceneCloud"
        },
        {
          name: "万效云",
          router: "/serviceProducts/EfficiencyCloud"
        },
        {
          name: "万学云",
          router: "/serviceProducts/LearnCloud"
        },
        {
          name: "万界云",
          router: "/serviceProducts/SaasCloud"
        },
        {
          name: "万擎云",
          router: "/serviceProducts/WanqingCloud"
        }
      ],
      [
        {
          name: "IP授权",
          router: "/serviceProducts/IP"
        },
        {
          name: "视听云服务",
          router: "/serviceProducts/AudioVisualCloud"
        },
        {
          name: "短信服务",
          router: "/serviceProducts/ShortMessage"
        },
        {
          name: "全景拍摄服务",
          router: "/serviceProducts/ShootingService"
        },
        {
          name: "VR带看",
          router: "/serviceProducts/VRSceneShow"
        },
        {
          name: "数字博物馆",
          router: "/serviceProducts/DigitalMuseum"
        }
      ]
    ],
    solutions: [
        [
          {
            name: "电商零售行业",
            router: "/solutions/OnlineRetailers"
          },
          {
            name: "房产家居行业",
            router: "/solutions/House"
          },
          {
            name: "智慧文旅",
            router: "/solutions/IntelligentCulturalTourism"
          },
          {
            name: "智慧教育",
            router: "/solutions/WisdomEducation"
          },
          {
            name: "汽车行业",
            router: "/solutions/AutomobileIndustry"
          },
          {
            name: "餐饮行业",
            router: "/solutions/CateringTrade"
          },
        ]
    ],
    isIE: browserVersion(),
  }),
  computed: {
    ...mapState(["officialWebsite"]),
  },
  watch: {
    $route(to) {
      const menuInfo = to.path.split("/");
      this.currentMenu = "/" + menuInfo[1];
      this.pathInfo = this.showHeaderMenu.indexOf(window.location.pathname.split("/")[1]) !== -1;
    },
  },
  mounted() {
    let _this = this;
    _this.pathInfo = _this.showHeaderMenu.indexOf(window.location.pathname.split("/")[1]) !== -1;
    document.addEventListener("click",()=>{
      _this.currentHover = '';
    })
  },
  methods: {
    handleMenuClick: function (menu, clickChildren, content){
      if(menu.children.length !== 0 && !clickChildren) return;
      if(content){
        if(content.outerChain){
          window.open(content.outerChain);
        }else if(content.router){
          if(content.isNewWindow){
            window.open(window.location.protocol + "//" + window.location.host + content.router)
          }else {
            this.$router.push(content.router)
          }
        }
      }
      this.showMenuChildren = false;
    },
    handleSaveTryout: function (){
      if(!this.form.name || !this.form.phone || !this.form.conpanyName){
        this.$lavMessage.warning("请填写必填项！");
        return;
      }
      this.$lavMessage.success("提交成功！");
      this.currentHover = "";
    },
    handleFooterClick: function (router, isNewWindow, outerChain){
      if(outerChain){
        window.open(outerChain);
      }else if(router){
        if(isNewWindow){
          window.open(window.location.protocol + "//" + window.location.host + router)
        }else {
          this.$router.push(router)
        }
      }
    }
  }
};
</script>
<style lang="scss" scoped>
a {
  text-decoration: none;
  color: #969799;
}
::v-deep .app-bar{
  line-height: 1;
  box-shadow: 0px 10px 20px 0px rgba(0, 114, 255, 0.1) !important;
  z-index: 1000 !important;
  hr {
    border-color: #333333;
    opacity: 0.2;
  }
  .v-toolbar__content {
    max-width: 1200px;
    margin: 0 auto;
    .logo-btn{
      padding: 0;
      .v-btn__content{
        opacity: 1 !important;
        height: 100%;
      }
    }
    .menu{
      margin: 0 auto;
      max-width: 1200px;
      .v-btn:not(.v-btn--round).v-size--default{
        min-width: unset;
      }
    }
    .menu-hover {
      border-bottom: 2px solid #333333;
    }
    .menu-title{
      font-size: 16px;
      height: 30px;
      position: relative;
      .menu-btn-active{
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%);
        height: 3px;
        width: 30px;
        background-color: #0568FD;
        //font-family: $bold-font-family;
      }
    }
    .menu-divider{
      width: 100%;
      position: fixed;
      top: 61px;
      left: 0;
      display: flex;
      justify-content: center;
      hr {
        max-width: 1200px;
        margin: 0 auto;
      }
    }
    .menu-children{
      //height: 320px;
      width: 100%;
      position: fixed;
      top: 60px;
      left: 0;
      background-color: #FFFFFF;
      display: flex;
      justify-content: center;
      padding: 40px 0 30px 0;
      box-shadow: 0px 10px 20px 0px rgba(0, 114, 255, 0.1);
      .menu-children-content{
        min-width: 1024px;
        max-width: 1200px;
        width: 100%;
        display: flex;
        height: 100%;
        //align-items: center;
        justify-content: center;
        position: relative;
        //font-family: SourceHanSansCN-Normal,sans-serif;
        hr {
          position: absolute;
          top: 0;
        }
        .children-title {
          font-size: 18px;
          height: 18px;
          //font-family: $normal-font-family;
        }
        .children-card {
          width: calc(100% - 50px);
          margin-left: 5px;
          .v-card__title{
            font-size: 16px;
          }
          .v-card__text{
            font-size: 10px;
          }
        }
      }
    }
  }
}
.suspend-btn{
  position: fixed;
  bottom: 20%;
  right: 20px;
  z-index:1001;
  ::v-deep .v-btn__content{
    white-space: initial;
    display: unset;
    flex: unset;
  }
  .hover-group{
    position: relative;
    .group-btn {
      width: 52px;
      height: 170px;
      padding-top: 10px;
      border-radius: 25px;
      box-shadow: 0px 0px 10px 2px rgb(0 114 255 / 10%);
      background-color: #FFFFFF;
      cursor: pointer;
    }
    .hover-content{
      position: absolute;
      right: 72px;
      bottom: 0;
      .content-phone{
        width: 280px;
        height: 320px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #FFFFFF;
        border-radius: 10px;
        box-shadow: 0px 0px 10px 2px rgb(0 114 255 / 10%);
      }
      .content-follow{
        width: 200px;
        height: 220px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #FFFFFF;
        border-radius: 10px;
        padding-bottom: 10px;
        box-shadow: 0px 0px 10px 2px rgb(0 114 255 / 10%);
      }
    }
    .content-tryout{
      width: 600px;
      height: 670px;
      display: flex;
      justify-content: center;
      border-radius: 10px;
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      background-color: #FFFFFF;
      box-shadow: 0px 0px 15px 5px rgb(0 114 255 / 10%);
      input {
        width: 360px;
        height: 50px;
        border: 1px solid rgba(127, 127, 127, 0.5);
        border-radius: 8px;
        margin-top: 12px;
        padding: 16px 14px;
      }
      input:focus {//获取焦点
        outline: unset;//边框不用border，用outline
        background: rgba(3, 16, 28, 0);//背景色
      }
      .tryout-btn {
        width: 360px;
        height: 60px;
        background: #2D87FF;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #FFFFFF;
        letter-spacing:8px;
        cursor: pointer;
      }
      .close-btn{
        position: relative;
        .icon {
          cursor: pointer;
          position: absolute;
          top: 10px;
          right: 20px;
        }
      }
    }
  }
  .hover-btn{
    width: 50px;
    height: 50px;
    border-radius: 25px;
    position: fixed;
    bottom: calc(20% - 70px);
    right: 20px;
    z-index:5;
    box-shadow: 0px 0px 10px 2px rgb(0 114 255 / 10%);
    cursor: pointer;
  }
}
footer {
  position: relative;
  z-index: 6;
  .footer-container{
    width: 100%;
    max-width: 1200px;
    //hr {
    //  border-color: #969799 !important;
    //  opacity: 0.4 !important;;
    //}
    .isIE {
      width: 1200px;
      margin: 0 auto;
      margin-left: -230px !important;
    }
    .content-info {
      color: #AEB8C8;
      font-size: 12px;
    }
    .footer-divider {
      width: 64px;
      height: 1px;
      background: #AEB8C8;
      margin-top: 8px;
    }
    ::v-deep .qr-code{
      .v-image__image--contain{
        width: 108px;
      }
    }
  }
}
</style>
